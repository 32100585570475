import React, { useState, useEffect } from "react";
import axios from "axios";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import { url_232 } from "../../../custom_utilities/api_services";
import "./Courses.scss";

const Courses = () => {
  const [courses, setCourses] = useState([]);

  useEffect(() => {
    getCourses();
  }, []);

  const getCourses = async () => {
    try {
      const response = await axios.get(url_232);

      if (response.data.status === 200) {
        const coursesData = response?.data?.data?.target?.map((course) => ({
          ...course,
          isHovered: false,
        }));
        setCourses(coursesData);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleCardHover = (index, isHovered) => {
    const updatedCourses = [...courses];
    updatedCourses[index].isHovered = isHovered;
    setCourses(updatedCourses);
  };

  const settings = {
    dots: false,
    infinite: true,
    autoplay: false,
    autoplaySpeed: 1500,
    arrows: false,
    slidesToShow: 4,
    slidesToScroll: 4,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
        },
      },
      {
        breakpoint: 551,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          rows: 2,
        },
      },
    ],
  };

  return (
    <div id="courses-id" className="course-inner-wrapper">
      {" "}
      {/* Added id here */}
      <header className="courses-header">
        <div className="main-heading">
          Explore Our <span> Courses</span>
        </div>
        <div className="sub-heading">
          Motion offers you an unmatched experience by combining the best
          teaching methodologies with powerful visualization.
        </div>
      </header>
      <div className="courses-container">
        <div className="courses-list">
          <Slider {...settings}>
            {courses?.map((course, index) => (
              <div
                className={`course ${course.isHovered ? "hovered" : ""}`}
                key={course?.target_id}
                onMouseEnter={() => handleCardHover(index, true)}
                onMouseLeave={() => handleCardHover(index, false)}
              >
                <div className="flip-card-inner">
                  <div
                    className="flip-card-front"
                    style={{ backgroundColor: course.backgroundColor }}
                  >
                    <img
                      src={course.target_icon}
                      alt={`${course?.target}-icon`}
                    />
                    <span>{course?.target}</span>
                  </div>
                  <div
                    className="flip-card-back"
                    style={{ backgroundColor: course.hover_background_color }}
                  >
                    <div className="flip-card-back-content">
                      <div className="flip-card-back-text-heading">
                        {course?.target}
                      </div>
                      <div className="flip-card-back-text">
                        <div
                          className="flip-card-back-class"
                          style={{ color: "black", fontWeight: 400 }}
                        >
                          Class
                        </div>
                        <div className="class-items">
                          {course?.classes?.map((cls, idx) => (
                            <div className="class-item" key={idx}>
                              <Link
                                to={`/store/${course?.target_id}/${cls?.class_id}`}
                                className="class-pill"
                              >
                                <span>{cls?.class_name}</span>{" "}
                              </Link>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </div>
  );
};

export default Courses;
