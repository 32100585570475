import React, { Fragment, useEffect, useState } from "react";
import "./preference_selection_modal.scss";
import { isEmpty } from "../../../../custom_utilities/custom_useful_functions";
import images from "../../../../utilities/images/images";
import VideoDrmPlayer from "../../features/video_feature/video_feature_content_component/VideoDrmPlayer";

const PreferenceSelectionModal = (props) => {
  let {
    userPreference,
    targets,
    classes,
    courses,
    batches,
    handleCloseModal,
    userData,
  } = props;

  const [activeStep, setActiveStep] = useState(0);
  const [targetDescription, setTargetDescription] = useState("");
  const [videoCryptId, setVideoCryptId] = useState("");

  useEffect(() => {
    const handleBackdropClick = (event) => {
      if (event.target === event.currentTarget) {
        handleCloseModal();
      }
    };

    const modalElement = document.getElementById("preference_selection_modal");
    modalElement.addEventListener("click", handleBackdropClick);

    return () => {
      modalElement.removeEventListener("click", handleBackdropClick);
    };
  }, [handleCloseModal]);

  const handleNextStep = () => {
    setActiveStep((prevStep) => prevStep + 1);
  };

  const handlePreviousStep = () => {
    if (activeStep === 4) {
      setActiveStep(0);
      setTargetDescription("");
      setVideoCryptId("");
    } else {
      setActiveStep((prevStep) => prevStep - 1);
    }
  };

  const openTargetDescription = (description, videoId) => {
    setTargetDescription(description);
    setVideoCryptId(videoId);
    setActiveStep(4);
  };
  const handleTargetSelection = (targetId, description, videoId, event) => {
    event.stopPropagation();

    if (props.handleChangeTarget) {
      props.handleChangeTarget({ target: { value: targetId } });
    }

    handleNextStep();
  };

  const renderStep = () => {
    switch (activeStep) {
      case 0:
        return (
          <section className="sec_1_wrapper">
            <div className="text_content_wrapper">
              <p className="text_content">Select Stream</p>
            </div>
            <div className="target_collection_wrapper">
              {!isEmpty(targets) &&
                targets?.target?.length > 0 &&
                targets.target.map((element, index) => (
                  <div
                    key={index}
                    className="single_target_wrapper"
                    onClick={(e) =>
                      handleTargetSelection(
                        element.target_id,
                        element.description,
                        element.video_id,
                        e
                      )
                    }
                    style={{ cursor: "pointer" }}
                  >
                    <div className="target-content">
                      <input
                        type="radio"
                        id={`target_${element.target_id}`}
                        name="target"
                        value={element.target_id}
                        checked={element.target_id === userPreference.target_id}
                        onChange={() => {}}
                        style={{ cursor: "pointer" }}
                      />
                      <label
                        htmlFor={`target_${element.target_id}`}
                        style={{
                          cursor: "pointer",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          width: "100%",
                        }}
                      >
                        <span>
                          {element.target}
                          <span className="target_short_desc">
                            ({element.short_desc})
                          </span>
                        </span>
                        <img
                          style={{
                            width: "25px",
                            height: "25px",
                            marginLeft: "auto",
                          }}
                          src={images.infosvg}
                          alt="Info"
                          className="info_icon"
                          onClick={(e) => {
                            e.stopPropagation();
                            openTargetDescription(
                              element.description,
                              element.video_id
                            );
                          }}
                        />
                      </label>
                    </div>
                  </div>
                ))}
            </div>
          </section>
        );
      case 1:
        return (
          <section className="sec_2_wrapper">
            <div className="text_content_wrapper">
              <p className="text_content">Current Class</p>
            </div>
            <div className="class_collection_wrapper">
              {classes?.classes?.map((element, index) => {
                return (
                  <div key={index} className="single_class_wrapper">
                    <input
                      type="radio"
                      id={`class_${element.class_id}`}
                      name="class"
                      value={element.class_id}
                      onChange={(event) => {
                        props.handleChangeClass(event);
                        handleNextStep();
                      }}
                      checked={element.class_id === userPreference.class_id}
                    />
                    <label htmlFor={`class_${element.class_id}`}>
                      {element.class}
                    </label>
                  </div>
                );
              })}
            </div>
          </section>
        );
      case 2:
        return (
          <section className="sec_3_wrapper">
            <div className="text_content_wrapper">
              <p className="text_content">Select Program/Medium</p>
            </div>
            <div className="course_collection_wrapper">
              {courses?.course?.map((element, index) => {
                return (
                  <div key={index} className="single_course_wrapper">
                    <input
                      type="radio"
                      id={`course_${element.course_id}`}
                      name="course"
                      value={element.course_id}
                      checked={userPreference.course_id === element.course_id}
                      onChange={(event) => {
                        props.handleChangeCourse(event);
                        handleNextStep();
                      }}
                    />
                    <label htmlFor={`course_${element.course_id}`}>
                      {element.course}
                    </label>
                  </div>
                );
              })}
            </div>
          </section>
        );
      case 3:
        return (
          <section className="sec_4_wrapper">
            <div className="text_content_wrapper">
              <p className="text_content">
                {userData?.is_academic === 1 ? "Select Batch" : ""}
              </p>
            </div>
            <div className="batch_collection_wrapper">
              {batches?.batch?.map((element, index) => {
                return userData?.is_academic === 1 ? (
                  <div key={index} className="single_batch_wrapper">
                    <input
                      type="radio"
                      id={`batch_${element.batch_id}`}
                      name="batch"
                      value={element.batch_id}
                      onChange={(event) => {
                        props.handleChangeBatch(event);
                      }}
                      checked={element.batch_id === userPreference.batch_id}
                    />
                    <label htmlFor={`batch_${element.batch_id}`}>
                      {element.batch}
                    </label>
                  </div>
                ) : (
                  <div
                    key={index}
                    className="single_batch_wrapper"
                    style={{ display: "none" }}
                  >
                    <input
                      type="radio"
                      id={`batch_${element.batch_id}`}
                      name="batch"
                      value={element.batch_id}
                      checked={element.batch_id === userPreference.batch_id}
                      disabled
                    />
                    <label htmlFor={`batch_${element.batch_id}`}>
                      {element.batch}
                    </label>
                  </div>
                );
              })}
            </div>
          </section>
        );
      case 4:
        return (
          <section className="sec_5_wrapper">
            <div className="text_content_wrapper">
              <p className="text_content">Target Description</p>
            </div>
            {videoCryptId && (
              <div style={{ width: "30rem" }}>
                <VideoDrmPlayer id_video={videoCryptId} />{" "}
              </div>
            )}

            <div className="description_wrapper">
              <p className="description_target">{targetDescription}</p>
            </div>
          </section>
        );
      default:
        return null;
    }
  };

  return (
    <Fragment>
      <div
        className="modal fade update_course_modal"
        id="preference_selection_modal"
        tabIndex="-1"
        aria-labelledby="preference_selection_modal_label"
        aria-hidden="true"
        style={{
          position: "fixed",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "600px",
          margin: "0",
          zIndex: 1050,
          borderRadius: "10px",
          overflowY: "hidden",
        }}
      >
        <div className="modal-dialog modal-lg modal-dialog-centered">
          <div className="modal-content">
            {activeStep !== 4 && (
              <div className="modal-header">
                {activeStep > 0 && (
                  <button
                    type="button"
                    className="btn btn-link"
                    onClick={handlePreviousStep}
                    style={{ background: "transparent", border: "none" }}
                  >
                    <i
                      className="bi bi-arrow-left"
                      style={{ color: "black", fontSize: "24px" }}
                    ></i>
                  </button>
                )}
                <h5
                  className="modal-title"
                  id="course_and_subject_preference_modal_label"
                >
                  Update your Package
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={() => handleCloseModal()}
                ></button>
              </div>
            )}

            {activeStep === 4 && (
              <div className="modal-header">
                <button
                  type="button"
                  className="btn btn-link"
                  onClick={handlePreviousStep}
                  style={{ background: "transparent", border: "none" }}
                >
                  <i
                    className="bi bi-arrow-left"
                    style={{ color: "black", fontSize: "24px" }}
                  ></i>
                </button>
                <h5>Back to Current Class</h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={() => handleCloseModal()}
                ></button>
              </div>
            )}

            <div className="modal-body" style={{ minHeight: "67vh" }}>
              <div className="modal_body_wrapper">{renderStep()}</div>
            </div>
            <div className="modal-footer">
              {activeStep === 3 && (
                <div className="modal_footer">
                  <button type="button" onClick={props.handleUpdateCourse}>
                    Update
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default PreferenceSelectionModal;
