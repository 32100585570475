import * as actionTypes from "./action_type";

const initialState = {
  subjectData: {
    subjectId: "",
    subjectName: "",
    subjectIcon: "",
  },
  topicData: {
    topicId: "",
    topicName: "",
    topicIcon: "",
    topicVideo: 0,
    is_live_class_available: 0,
    is_excersice_available: 0,
    is_notes_available: 0,
    is_dpp_available: 0,
    is_additional_video_available: 0,
    is_video_available: 0,
  },
  videoData: {},
  notesData: {},
  liveStreamData: {},
  exerciseData: {},
  exerciseResultData: {},
  liveStreamVideoData: {},
  feature: {
    feature_type: 1,
  },
  commonBannerData: {},
  pyqSelectedSubjectInTopicWise: "",
};

const userActivityReducer = (
  state = initialState,
  { type, payload = null }
) => {
  switch (type) {
    case actionTypes.SET_SUBJECT_DATA:
      return setSubjectData(state, payload);
    case actionTypes.SET_TOPIC_DATA:
      return setTopicData(state, payload);
    case actionTypes.SET_COURSEID_DATA:
      return setCourseIdData(state, payload);
    case actionTypes.SET_PYQTOPIC_DATA:
      return setPyqTopicData(state, payload);
    case actionTypes.SET_PYQTOPIC_TOPIC_SUBJECT:
      return setPyqTopicSubject(state, payload);
    case actionTypes.SET_EXAM_CRACKER_TOPIC_DATA:
      return setExamCrackerTopicData(state, payload);
    case actionTypes.SET_FEATURE:
      return setFeature(state, payload);
    case actionTypes.SET_VIDEO_DATA:
      return setVideoData(state, payload);
    case actionTypes.SET_RECORDED_VIDEO_DATA:
      return setRecordedVideoData(state, payload);
    case actionTypes.SET_NOTES_DATA:
      return setNotesData(state, payload);
    case actionTypes.SET_LIVE_STREAM_DATA:
      return setLiveStreamData(state, payload);
    case actionTypes.SET_EXERCISE_DATA:
      return setExerciseData(state, payload);
    case actionTypes.SET_EXERCISE_RESULT_DATA:
      return setExerciseResultData(state, payload);
    case actionTypes.SET_LIVE_STREAM_VIDEO_DATA:
      return setLiveStreamVideoData(state, payload);
    case actionTypes.REMOVE_ACTIVITY_DATA:
      return removeActivityData(state);
    case actionTypes.SET_COMMON_BANNER_DATA:
      return setCommonBannerData(state, payload);
    default:
      return state;
  }
};

const setSubjectData = (state, payload) => {
  let { subjectId, subjectName, subjectIcon } = payload;

  state = {
    ...state,
    subjectData: { ...state.subjectData, subjectId, subjectName, subjectIcon },
  };

  return state;
};

const setTopicData = (state, payload) => {
  let {
    topicId,
    topicName,
    topicIcon,
    topicVideo,
    is_live_class_available,
    is_excersice_available,
    is_notes_available,
    is_dpp_available,
    is_additional_video_available,
    is_video_available,
  } = payload;

  state = {
    ...state,
    topicData: {
      ...state.topicData,
      topicId,
      topicName,
      topicIcon,
      topicVideo,
      is_live_class_available,
      is_excersice_available,
      is_notes_available,
      is_dpp_available,
      is_additional_video_available,
      is_video_available,
    },
  };

  return state;
};
const setCourseIdData = (state, payload) => {
  let { freeCourseId } = payload;
  state = {
    ...state,
    courseData: { ...state.courseData, freeCourseId },
  };
  return state;
};

const setPyqTopicData = (state, payload) => {
  console.log("payload----------->", payload);
  let { subId, subName } = payload;
  state = {
    ...state,
    pyqtopicData: { ...state.pyqtopicData, subId, subName },
  };
  return state;
};

const setPyqTopicSubject = (state, payload) => {
  console.log("payload----------->", payload);
  let { subId, subName } = payload;
  state = {
    ...state,
    pyqSelectedSubjectInTopicWise: subName,
  };
  return state;
};

const setExamCrackerTopicData = (state, payload) => {
  let { subject } = payload;

  state = {
    ...state,
    ExamCrackerTopicData: { ...state.ExamCrackerTopicData, subject },
  };

  return state;
};

const setFeature = (state, payload) => {
  state = {
    ...state,
    feature: {
      ...state.feature,
      feature_type: payload,
    },
  };
  return state;
};

const setVideoData = (state, payload) => {
  state = { ...state, videoData: payload };
  return state;
};

const setRecordedVideoData = (state, payload) => {
  state = { ...state, recordedVideoData: payload };
  return state;
};

const setNotesData = (state, payload) => {
  state = { ...state, notesData: payload };
  return state;
};

const setLiveStreamData = (state, payload) => {
  state = { ...state, liveStreamData: payload };
  return state;
};

const setExerciseData = (state, payload) => {
  state = { ...state, exerciseData: payload };
  return state;
};

const setExerciseResultData = (state, payload) => {
  state = { ...state, exerciseResultData: payload };
  return state;
};

const setLiveStreamVideoData = (state, payload) => {
  state = { ...state, liveStreamVideoData: payload };
  return state;
};

const removeActivityData = (state) => {
  state = {
    ...state,
    subjectData: {
      subjectId: "",
      subjectName: "",
      subjectIcon: "",
    },
    topicData: {
      topicId: "",
      topicName: "",
      topicIcon: "",
      topicVideo: 0,
      is_live_class_available: 0,
      is_excersice_available: 0,
      is_notes_available: 0,
      is_dpp_available: 0,
      is_additional_video_available: 0,
      is_video_available: 0,
    },
    videoData: {},
    recordedVideoData: "",
    notesData: {},
    liveStreamData: {},
    exerciseData: {},
    exerciseResultData: {},
    liveStreamVideoData: {},
    feature: {
      feature_type: 1,
    },
  };

  return state;
};

const setCommonBannerData = (state, payload) => {
  state = { ...state, commonBannerData: payload };
  return state;
};

export default userActivityReducer;
